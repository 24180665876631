<template>
    <div class="details_section">
        <div class="left_area">
            <button type="button" class="toggle_card" @click="toggleAllCard">{{ allOpen ? 'Close' : 'Open' }} All</button>
            <div class="profile_stat">
                <ul class="action_status">
                    <li>
                        <h2>{{ stats.email_sent }}</h2>
                        <p>Email Sent</p>
                    </li>
                    <li>
                        <h2>{{ stats.email_opened }}</h2>
                        <p>Opened</p>
                    </li>
                    <li class="ml-auto">
                        <div class="score_circle" style="width:44px; height:44px; padding: 4px;">
                            <div class="inner_circle">
                                <div class="score">{{ stats.email_rate }}<span>%</span></div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="44" height="44">
                                <circle cx="22" cy="22" r="20" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="202 - (stats.email_rate * 2.02)" />
                            </svg>
                        </div>
                    </li>
                </ul>
                <ul class="action_status">
                    <li>
                        <h2>{{ stats.sms_sent }}</h2>
                        <p>SMS Sent</p>
                    </li>
                    <li>
                        <h2>{{ stats.sms_reply }}</h2>
                        <p>Replied</p>
                    </li>
                    <li class="ml-auto">
                        <div class="score_circle" style="width:44px; height:44px; padding: 4px;">
                            <div class="inner_circle">
                                <div class="score">{{ stats.sms_rate }}<span>%</span></div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="44" height="44">
                                <circle cx="22" cy="22" r="20" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#2F7FED" :stroke-dasharray="202"  :stroke-dashoffset="202 - (stats.sms_rate * 2.02)" />
                            </svg>
                        </div>
                    </li>
                </ul>
            </div>
            <draggable v-model="centreView" tag="ul" class="report_list" :animation="200" item-key="title" @end="handleProfileSetting('centre')">
                <template #item="{element}">
                    <li :class="{'w-100' : element.fullWidth}">
                        <!-- <button type="button" v-if="element.type !== 'sequence' && element.type !== 'broadcasts'" class="add_btn" @click="addCard(element.action)">
                            <i class="fas fa-plus"></i>Add
                            <div class="quick_info"><p>{{element.tooltip1}}</p></div>
                        </button> -->
                        <div class="profile_card">
                            <div class="card_hdr" :class="(element.type == 'broadcasts' && broadcasts.data && broadcasts.data.length) || (element.type == 'progress_tracking' && contactProgressTrackings.data && contactProgressTrackings.data.length) || (element.type == 'habit_tracking' && contactHabitTrackings.data && contactHabitTrackings.data.length) || (element.type == 'forms' && contactForms.data && contactForms.data.length) || (element.type == 'playbooks' && contactPlaybooks.data && contactPlaybooks.data.length) || (element.type == 'journeys' && contactJourneys.data && contactJourneys.data.length) || (element.type == 'pages' && contactPages.data && contactPages.data.length) || (element.type == 'sequence' && contactSequences.data && contactSequences.data.length) ? 'band' : '' ">
                                <h3>{{element.type == "broadcasts" ? 'Recent' : 'Active'}} {{element.header}}</h3>
                                <button type="button" v-tooltip="element.tooltip1" position="left" v-if="element.type !== 'sequence' && element.type !== 'broadcasts'" class="add_btn" @click="addCard(element.action)">
                                    <i class="fas fa-plus"></i>Add
                                    <!-- <div class="quick_info"><p>{{element.tooltip1}}</p></div> -->
                                </button>
                                <button type="button" class="toggle_btn" @click="toggleInfo($event, element.header)"><i class="fas fa-chevron-down"></i></button>
                            </div>
                            <div class="card_body">
                                <div v-if="element.type == 'broadcasts'">
                                    <div class="status_list mt-2" v-if="broadcasts.data && broadcasts.data.length && (!detailsLoader || selectedTab != element.header)">
                                        <div class="status_list_item" v-for="(broadcast, b) in broadcasts.data" :key="b">
                                            <h4>{{broadcast.subject}}</h4>
                                            <ul class="action_status broadcast">
                                                <li v-for="(logs, l) in broadcast.broadcast_email_logs" :key="l">
                                                    <div class="broadcast_step">
                                                        <h3>{{ logs.event }} on</h3>
                                                        <p>{{ moment(logs.date_created).format('DD/MM/YYYY') }}</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div  class="empty_list" v-else>
                                        <h4 v-if="detailsLoader && selectedTab == element.header">Loading...</h4>
                                        <template v-else>
                                            <img src="@/assets/images/empty_state.svg">
                                            <h4>No Broadcasts Found</h4>
                                        </template>
                                    </div>
                                </div>
                                <div v-if="element.type == 'progress_tracking'">
                                    <div v-if="contactProgressTrackings.data && contactProgressTrackings.data.length && (!progressTrackingLoader || selectedTab != element.header)">
                                        <div class="status_list mb-5" v-for="(progressTracking, p) in contactProgressTrackings.data" :key="p">
                                            <h4>
                                                {{ progressTracking.progress_tracking.name }} <i class="fas fa-search-plus ml-auto pointer" @click="viewProgressReport(progressTracking)"></i>
                                                <button type="button" class="del_btn ml-2" @click="handleStopProgressTracking([progressTracking.progress_tracking_id])">
                                                    <i class="fas fa-trash-alt"></i>
                                                    <div class="quick_info"><p>Delete client tracking </p></div>
                                                </button>
                                            </h4>
                                            <ul class="action_status">
                                                <li>
                                                    <div class="broadcast_step">
                                                        <h3>{{ progressTracking.participation_score }} %</h3>
                                                        <p>Participation</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="broadcast_step">
                                                        <h3>{{ progressTracking.progress_score }} %</h3>
                                                        <p>Progress Score</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div  class="empty_list" v-else>
                                        <h4 v-if="progressTrackingLoader && selectedTab == element.header">Loading...</h4>
                                        <template v-else>
                                            <img src="@/assets/images/empty_state.svg">
                                            <h4>No Client Tracking Found</h4>
                                        </template>
                                    </div>
                                </div>
                                <div v-if="element.type == 'habit_tracking'">
                                    <div v-if="contactHabitTrackings.data && contactHabitTrackings.data.length && (!habitTrackingLoader || selectedTab != element.header)">
                                        <div class="status_list mb-5" v-for="(habitTracking, p) in contactHabitTrackings.data" :key="p">
                                            <h4>
                                                {{ habitTracking.habit_tracking.name }} <i class="fas fa-search-plus ml-auto pointer" @click="viewHabitReport(habitTracking)"></i>
                                                <button type="button" class="del_btn ml-2" @click="handleStopHabitTracking([habitTracking.habit_tracking_id])">
                                                    <i class="fas fa-trash-alt"></i>
                                                    <div class="quick_info"><p>Delete client tracking</p></div>
                                                </button>
                                            </h4>
                                            <ul class="action_status">
                                                <li>
                                                    <div class="broadcast_step">
                                                        <h3>{{ habitTracking.participation_score }} %</h3>
                                                        <p>Participation</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="broadcast_step">
                                                        <h3>{{ habitTracking.habit_score }} %</h3>
                                                        <p>Habit Score</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="broadcast_step">
                                                        <h3>{{ habitTracking.best_streak }} Days</h3>
                                                        <p>Best Streak</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div  class="empty_list" v-else>
                                        <h4 v-if="habitTrackingLoader && selectedTab == element.header">Loading...</h4>
                                        <template v-else>
                                            <img src="@/assets/images/empty_state.svg">
                                            <h4>No Habit Scenarios Found</h4>
                                        </template>
                                    </div>
                                </div>
                                <div v-if="element.type == 'forms'">
                                    <div v-if="contactForms.data && contactForms.data.length && (!formLoader || selectedTab != element.header)">
                                        <div class="playbook_status mb-5" v-for="(form, f) in contactForms.data" :key="f">
                                            <h4>
                                                {{ form.survey_name }} <i class="fas fa-search-plus ml-auto pointer" @click="viewFormDetail(form)"></i>
                                                <button type="button" class="del_btn ml-2" @click="handleRestrictForm([form.survey_id])">
                                                    <i class="fas fa-trash-alt"></i>
                                                    <div class="quick_info"><p>Remove form form portal</p></div>
                                                </button>
                                            </h4>
                                            <h6 :class="{'opened' : (form.is_response && form.is_response == 1 && form.is_signature == 0), 'not_delivered':( !form.is_response || (form.is_response && form.is_response == 0)), 'delivered': (form.is_response && form.is_response == 1 && form.is_signature == 1) }">
                                                {{ form.is_response && form.is_response == 1 ? (form.is_signature == 1 ? 'Signed' : 'Submitted') : 'Due' }}
                                            </h6>
                                            <label :for="`contact-form-${f}`" class="switch_option capsule_btn">
                                                <h5>Show in Portal</h5>
                                                <input type="checkbox" :id="`contact-form-${f}`" @click="handleEnableDisableForm(form, $event)" :checked="form.link && form.link.client_portal == 1" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="empty_list" v-else>
                                        <h4 v-if="formLoader && selectedTab == element.header">Loading...</h4>
                                        <template v-else>
                                            <img src="@/assets/images/empty_state.svg">
                                            <h4>No Forms Found</h4>
                                        </template>
                                    </div>
                                </div>
                                <div v-if="element.type == 'playbooks'">
                                    <div v-if="contactPlaybooks.data && contactPlaybooks.data.length && (!detailsLoader || selectedTab != element.header)">
                                        <div class="playbook_status mb-5" v-for="(playbook, p) in contactPlaybooks.data" :key="p">
                                            <h4>
                                                {{playbook.name}}
                                                <button type="button" class="del_btn" @click="handleRestrictPlaybook([playbook.playbook_id])">
                                                    <i class="fas fa-trash-alt"></i>
                                                    <div class="quick_info"><p>Restrict Playbook</p></div>
                                                </button>
                                            </h4>
                                            <h6>{{ playbook.step_detail ? playbook.step_detail.completed_step : 0 }} out of {{ playbook.step_detail ? playbook.step_detail.total_steps : 0 }} steps completed</h6>
                                        </div>
                                    </div>
                                    <div class="empty_list" v-else>
                                        <h4 v-if="detailsLoader && selectedTab == element.header">Loading...</h4>
                                        <template v-else>
                                            <img src="@/assets/images/empty_state.svg">
                                            <h4>No Playbooks Found</h4>
                                        </template>
                                    </div>
                                </div>
                                <div v-if="element.type == 'journeys'">
                                    <div v-if="contactJourneys.data && contactJourneys.data.length && (!detailsLoader || selectedTab != element.header)">
                                        <div class="playbook_status mb-5" v-for="(journeys, j) in contactJourneys.data" :key="j">
                                            <h4>{{journeys.name}}
                                                <button type="button" class="del_btn" @click="handleTerminateJourney([journeys.automation_id])">
                                                    <i class="fas fa-trash-alt"></i>
                                                    <div class="quick_info"><p>Terminate journey</p></div>
                                                </button>
                                            </h4>
                                            <!-- <h6>Moved stage in pipeline</h6> -->
                                        </div>
                                    </div>
                                    <div class="empty_list" v-else>
                                        <h4 v-if="detailsLoader && selectedTab == element.header">Loading...</h4>
                                        <template v-else>
                                            <img src="@/assets/images/empty_state.svg">
                                            <h4>No Journeys Found</h4>
                                        </template>
                                    </div>
                                </div>
                                <div v-if="element.type == 'pages'">
                                    <div v-if="contactPages.data && contactPages.data.length && (!detailsLoader || selectedTab != element.header)">
                                        <div class="playbook_status mb-5" v-for="(page, p) in contactPages.data" :key="p">
                                            <h4>{{page.title}}
                                                <button type="button" class="del_btn"  @click="handleRestrictPage([page.asset_id])">
                                                    <i class="fas fa-trash-alt"></i>
                                                    <div class="quick_info"><p>Restrict page</p></div>
                                                </button>
                                            </h4>
                                            <label :for="`contact-page-${p}`" class="switch_option capsule_btn">
                                                <h5>Show in Portal</h5>
                                                <input type="checkbox" :id="`contact-page-${p}`" @click="handleEnableDisablePage(page, $event)" :checked="page.client_portal" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div  class="empty_list" v-else>
                                        <h4 v-if="detailsLoader && selectedTab == element.header">Loading...</h4>
                                        <template v-else>
                                            <img src="@/assets/images/empty_state.svg">
                                            <h4>No Pages Found</h4>
                                        </template>
                                    </div>
                                </div>
                                <div v-if="element.type == 'sequence'">
                                    <div v-if="contactSequences.data && contactSequences.data.length && (!detailsLoader || selectedTab != element.header)">
                                        <div class="status_list negetive_margin mb-5" v-for="(sequence, s) in contactSequences.data" :key="s">
                                            <h4>{{ sequence.name }}
                                                <ul>
                                                    <li>
                                                        <div class="score_circle" style="width:34px; height:34px; padding: 2px;">
                                                            <div class="inner_circle">
                                                                <div class="score" style="transform: scale(0.7);">{{ sequence.latest_open_rate ? sequence.latest_open_rate : 0 }}<span>%</span></div>
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="34" height="34">
                                                                <circle cx="17" cy="17" r="15" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="202 - ((sequence.latest_open_rate ? sequence.latest_open_rate : 0) * 2.02)"/>
                                                            </svg>
                                                        </div>
                                                        <p class="ml-2">Open Rate</p>
                                                    </li>
                                                    <li>
                                                        <div class="score_circle" style="width:34px; height:34px; padding: 2px;">
                                                            <div class="inner_circle">
                                                                <div class="score" style="transform: scale(0.7);">{{ sequence.latest_clicked_rate ? sequence.latest_clicked_rate : 0 }}<span>%</span></div>
                                                            </div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="34" height="34">
                                                                <circle cx="17" cy="17" r="15" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="202 - ((sequence.latest_clicked_rate ? sequence.latest_clicked_rate : 0) * 2.02)"/>
                                                            </svg>
                                                        </div>
                                                        <p class="ml-2">Click Rate</p>
                                                    </li>
                                                </ul>
                                                <button type="button" class="del_btn" @click="handleStopSequence([sequence.sequence_id])">
                                                    <i class="fas fa-trash-alt"></i>
                                                    <div class="quick_info"><p>Remove sequence</p></div>
                                                </button>
                                            </h4>
                                            <div class="swiper_section">
                                                <swiper :slidesPerView="'auto'" :spaceBetween="0" :navigation='false' mousewheel-control="false" class="seq_listing">
                                                    <swiper-slide v-for="(sequenceLog, e) in sequence.latest_logs" :key="e">
                                                        <div class="seq_wpr" :class="{'schedule' : sequenceLog.event == 'scheduled'}">
                                                            <label>{{ sequence.email_order }} <div class="quick_info">
                                                                <p>{{ sequenceLog.message.subject }}</p>
                                                            </div></label>
                                                            <ul class="seq_method">
                                                                <li v-if="sequenceLog.event == 'delivered' || sequenceLog.event == 'mixed' "><img src="@/assets/images/sms.svg" alt=""></li>
                                                                <li v-if="sequenceLog.event != 'delivered' || sequenceLog.event == 'mixed' "><img src="@/assets/images/mail.svg" alt=""></li>
                                                            </ul>
                                                            <div class="action_info">
                                                                <div class="action" :class="{'pointer' : sequenceLog.event == 'mixed'}">{{ sequenceLog.event }}
                                                                    <div class="quick_info" v-if="sequenceLog.event == 'mixed'"><p>Email Opened <br/> SMS Replied</p></div>
                                                                </div>
                                                                <p>{{ moment(sequenceLog.date_created).format('DD/MM/YYYY') }}</p>
                                                            </div>
                                                            <span class="curve_space" v-if="sequenceLog.event == 'scheduled'"></span>
                                                        </div>
                                                    </swiper-slide>
                                                </swiper>
                                            </div>
                                        </div>
                                    </div>
                                    <div  class="empty_list" v-else>
                                        <h4 v-if="detailsLoader && selectedTab == element.header">Loading...</h4>
                                        <template v-else>
                                            <img src="@/assets/images/empty_state.svg">
                                            <h4>No Sequences Found</h4>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </template>
            </draggable>
        </div>
        <!-- <draggable v-model="rightView" tag="ul" class="profle_details" :animation="200" item-key="type" @end="handleProfileSetting('right')">
            <template #item="{element}">
                <li>
                    <button type="button" class="add_btn" v-if="element.type == 'tags' || element.type == 'field'" @click="addCard(element.action)"><i class="fas fa-plus"></i>Add</button>
                    <div class="info_card">
                        <div class="title">
                            {{element.title}}
                            <img v-if="element.type == 'dob'" src="@/assets/images/calendar-alt.svg" alt="">
                            <img v-if="element.type == 'reward'" src="@/assets/images/gift.svg" alt="">
                            <img v-if="element.type == 'tags'" src="@/assets/images/tags-alt.svg" alt="">
                        </div>
                        <div class="card_body has_icon" v-if="element.type == 'dob'">
                            <h4>Date of Birth</h4>
                            <h2>{{ contact.birthdate ? moment(contact.birthdate).format('ll') : 'Missing Birthday!' }}</h2>
                            <h6>{{ contact.birthdate ? getRemainingDays(contact.birthdate) + ' to go' : '' }}</h6>
                        </div>
                        <div class="card_body" v-if="element.type == 'reward'">
                            <h4>Available Points</h4>
                            <h2>{{ totalPoints }}</h2>
                            <div class="action_btn">
                                <button class="btn cancel_btn" @click="addReward = true">Add</button>
                                <button class="btn save_btn" @click="redeem = true">Redeem</button>
                            </div>
                        </div>
                        <div class="card_body" v-if="element.type == 'tags'">
                            <ul class="tags" v-if="contactTags && contactTags.length">
                                <li :id="`contact-profile-tag-${t}`" v-for="(tag, t) in contactTags" :key="t">
                                    <h4 :style="{ background: getTagBackground(tag.tag_type) }">{{tag.name}}
                                        <span class="remove_btn" @click="handleRemoveTag(tag, t)">
                                            <i class="fas fa-times"></i>
                                        </span>
                                    </h4>
                                </li>
                            </ul>
                            <div class="no_content" v-else>
                                <img src="@/assets/images/empty_state.svg">
                                <h3>No Tags</h3>
                            </div>
                        </div>
                        <div class="card_body" v-if="element.type == 'field'">
                            <ul class="custom_fields" v-if="customFields && customFields.length">
                                <li v-for="(field, f) of customFields" :key="f">
                                    <span>
                                        {{ field.name }} <br />
                                        <span class="custom-field-ans">{{ contact.custom_field[field.id] ? contact.custom_field[field.id] : '-' }}</span>
                                    </span>
                                </li>
                            </ul>
                            <div class="no_content" v-else>
                                <img src="@/assets/images/empty_state.svg">
                                <h3>No Custom Fields</h3>
                            </div>
                        </div>
                    </div>
                </li>
            </template>
        </draggable> -->
        <add-reward v-model="addReward" :contact="contact" />
    </div>
    <!-- <div class="action_wpr" v-show="!editProfile && !showFormDetail">
        <button type="button" class="btn danger_btn" @click="deleteContact(contact.id)">Delete</button>
        <button type="button" class="btn save_btn" @click="editProfile = true">Edit</button>
    </div> -->

    <div class="form_view" v-if="habitReport">
        <button class="close_btn pointer" @click="habitReport = false"><i class="fas fa-times"></i></button>
        <habit-report v-model="habitReport" :habit-tracking="selectedRelation" :contact-relation="contact" />
    </div>
    <div class="form_view" v-if="progressReport">
        <button class="close_btn pointer" @click="progressReport = false"><i class="fas fa-times"></i></button>
        <progress-report v-model="progressReport" :progress-tracking="selectedRelation" :contact-relation="contact" />
    </div>

    <redeem-component v-model="redeem" :contact="contact" />
    <!-- <edit-profile v-model="editProfile" :contact="contact" /> -->
    <start-journey v-model="startJourney" :contacts="[contact.id]" />
    <send-playbook v-model="assignPlaybook" :contacts="[contact.id]" />
    <send-form v-model="sendForm" :contacts="[contact.id]" />
    <send-page v-model="sendPage" :contacts="[contact.id]" />
    <start-progress-tracking v-model="startProgressTracking" :contacts="[contact.id]" />
    <start-habit-tracking v-model="startHabitTracking" :contacts="[contact.id]" />
    <!-- <add-tag v-model="addTag" :contacts="[contact.id]" /> -->
    <add-custom-field v-model="addCustomField" />
    <form-detail v-model="showFormDetail" :form-details="formDetails"/>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import SwiperCore, { Navigation } from 'swiper'
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

    const ProgressReport = defineAsyncComponent(() => import('@/pages/progress-tracking/components/ProgressReport'))
    const HabitReport = defineAsyncComponent(() => import('@/pages/habit-tracking/components/HabitReport'))
    const AddReward = defineAsyncComponent(() => import('@/pages/contact/components/profile/components/AddReward'))
    const RedeemComponent = defineAsyncComponent(() => import('@/pages/contact/components/profile/components/Redeem'))
    // const EditProfile = defineAsyncComponent(() => import('@/pages/contact/components/profile/components/EditProfile'))
    // const AddTag = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/AddTag'))
    const StartJourney = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartJourney'))
    const SendPlaybook = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendPlaybook'))
    const SendForm = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendForm'))
    const SendPage = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendPage'))
    const StartProgressTracking = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartProgressTracking'))
    const StartHabitTracking = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartHabitTracking'))
    const AddCustomField = defineAsyncComponent(() => import('@/pages/contact/components/profile/components/AddCustomField'))
    const FormDetail = defineAsyncComponent(() => import('@/pages/contact/components/profile/components/FormDetail'))

    import moment from 'moment'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Draggable from 'vuedraggable'
    import constants from '@/utils/Constant'

    import 'swiper/swiper-bundle.min.css'
    SwiperCore.use([Navigation])

    export default{
        name: 'Tab Profile Details',

        data() {
            return {
                editProfile: false,
                moment,
                confirmScreen: false,
                showFormDetail: false,
                habitReport: false,
                progressReport: false,
                centreView:[
                    {
                        type: 'broadcasts',
                        header: 'Broadcasts',
                        fullWidth: true,
                    },
                    {
                        type: 'sequence',
                        header: 'Sequences',
                        fullWidth: true,
                        tooltip2: 'Remove sequence',
                    },
                    {
                        type: 'habit_tracking',
                        header: 'Habit Scenarios',
                        tooltip1: 'Start a habit scenario',
                        action: 'startHabitTracking'
                    },
                    {
                        type: 'progress_tracking',
                        header: 'Progress Scenarios',
                        tooltip1: 'Start a progress scenario',
                        action: 'startProgressTracking'
                    },
                    {
                        type: 'playbooks',
                        header: 'Playbooks',
                        tooltip1: 'Assign playbook',
                        action: 'assignPlaybook'
                    },
                    {
                        type: 'forms',
                        header: 'Forms',
                        tooltip1: 'Send form',
                        action: 'sendForm'
                    },
                    {
                        type: 'pages',
                        header: 'Pages',
                        tooltip1: 'Send page',
                        action: 'sendPage'
                    },
                    {
                        type: 'journeys',
                        header: 'Journeys',
                        tooltip1: 'Start journey',
                        action: 'startJourney'
                    },
                ],
                rightView:[
                    {
                        type: 'dob',
                        title: 'Birthday',
                    },
                    {
                        type: 'reward',
                        title: "Rewards",
                    },
                    {
                        type: 'tags',
                        title: 'Tags',
                        action: 'addTag'
                    },
                    {
                        type: 'field',
                        title: 'Custom Fields',
                        action: 'addCustomField'
                    },
                ],
                redeem: false,
                addReward: false,
                contactTags: [],
                profileSetting: {
                    user_id: '',
                    left: [],
                    centre: [],
                    right: [],
                },
                startJourney: false,
                assignPlaybook: false,
                sendForm: false,
                sendPage: false,
                startProgressTracking: false,
                startHabitTracking: false,
                addTag: false,
                addCustomField: false,
                customFields: [],
                formDetails: {
                    respondent_info: {
                        name: '',
                        email: '',
                        number: '',
                        full_address: '',
                        birthdate: '',
                    },
                    survey_info: {
                        survey_name: ''
                    },
                    custom_fields: [],
                    custom_fields_answers: {},
                },
                selectedRelation: {},
                selectedTab: '',
                opendTab: [],
                allOpen: false,
            };
        },

        props: {
            contact: Object,
        },

        watch: {
            'form.country' (country) {
                const vm = this;

                vm.resetTimezoneByCountry([]);

                if (country) {
                    vm.getTimezonesByCountry({ country });
                }
            },

            selectedTags (val) {
                const vm       = this;

                vm.contactTags = [];
                vm.contactTags = vm.filterTags(val);
            },

            bulkActionWatcher () {
                const vm   = this;
                const val  = vm.updatedAction;

                vm.getActions(val, true);
            },

            customFieldsAll () {
                const vm = this;

                vm.customFields = JSON.parse(JSON.stringify(vm.customFieldsAll));
                vm.customFields = vm.customFields.reverse().slice(0, 5);
            }
        },

        components:{
            Draggable,
            Swiper,
            SwiperSlide,
            ProgressReport,
            HabitReport,
            AddReward,
            RedeemComponent,
            // EditProfile,
            StartJourney,
            SendPlaybook,
            SendForm,
            SendPage,
            StartProgressTracking,
            StartHabitTracking,
            // AddTag,
            AddCustomField,
            FormDetail,
        },

        computed: {
            ...mapState({
                contactLoader: state => state.contactModule.contactSaveLoader,
                genderList: state => state.contactModule.genderList,
                statusesGroup: state => state.contactModule.statusesGroup,
                countries: state => state.commonModule.countries,
                customFieldsAll: state => state.contactModule.customFieldsAll,
                rewards: state => state.contactModule.contactRewards,
                contactProfileSetting: state => state.authModule.contactProfileSetting,
                stats: state => state.contactModule.stats,
                detailsLoader: state => state.contactModule.contactDetailsLoader,
                totalPoints: state => state.contactModule.rewardsTotalPoints,
                selectedTags: state => state.contactModule.selectedTags,
                contactForms: state => state.formModule.contactForms,
                broadcasts: state => state.contactModule.broadcastLogs,
                contactHabitTrackings: state => state.habitTrackingModule.contactHabitTrackings,
                contactProgressTrackings: state => state.progressTrackingModule.contactProgressTrackings,
                contactPages: state => state.contactModule.contactPages,
                contactSequences: state => state.contactModule.contactSequences,
                contactPlaybooks: state => state.contactModule.contactPlaybooks,
                contactJourneys: state => state.contactModule.contactJourneys,
                updatedAction: state => state.contactModule.updatedAction,
                bulkActionWatcher: state => state.contactModule.bulkActionWatcher,
                formLoader: state => state.formModule.formLoader,
                progressTrackingLoader: state => state.progressTrackingModule.contactProgressTrackingLoader,
                habitTrackingLoader: state => state.habitTrackingModule.contactHabitTrackingLoader,
            }),

            ...mapGetters({
                filterTags: 'tagModule/FILTER_TAGS_TYPE',
            })
        },

        mounted() {
            const vm = this;

            vm.opendTab = [];
            vm.resetDetailView();

            vm.centreView.forEach((view) => {
                vm.getActions(view.header);
            });

            setTimeout(function () {
                vm.resetDetail();
            }, 100);
        },

        methods: {
            ...mapActions({
                getStatuses: 'contactModule/getStatuses',
                getCountries: 'commonModule/getCountries',
                getTimezonesByCountry: 'commonModule/getTimezonesByCountry',
                bulkDelete: 'contactModule/bulkDelete',
                getContactTags: 'contactModule/getContactTags',
                updateContactProfileSetting: 'authModule/updateContactProfileSetting',
                getContactRewardPoints: 'contactModule/getContactRewardPoints',
                getContactBroadcastLogs: 'contactModule/getContactBroadcastLogs',
                getContactForms: 'formModule/getContactForms',
                getContactProgressTracking: 'progressTrackingModule/getContactProgressTracking',
                getContactHabitTracking: 'habitTrackingModule/getContactHabitTracking',
                getContactPages: 'contactModule/getContactPages',
                getContactJourneys: 'contactModule/getContactJourneys',
                getContactPlaybooks: 'contactModule/getContactPlaybooks',
                getContactSequences: 'contactModule/getContactSequences',
                removeTag: 'tagModule/removeTag',
                stopProgress: 'progressTrackingModule/stopProgress',
                stopHabit: 'habitTrackingModule/stopHabit',
                restrictForm: 'formModule/restrictForm',
                restrictPlaybook: 'playbookModule/restrictPlaybook',
                restrictPage: 'pageModule/restrictPage',
                terminateJourney: 'journeyModule/terminateJourney',
                enableDisableForm: 'formModule/enableDisableForm',
                stopSequence: 'sequenceModule/stopSequence',
                viewFormResponse: 'formModule/viewFormResponse',
                enableDisablePage: 'pageModule/enableDisablePage',
            }),

            ...mapMutations({
                resetTimezoneByCountry: 'commonModule/SET_TIMEZONES_BY_COUNTRY',
            }),

            deleteContact (id) {
                const vm = this;
                const option = Helper.swalConfirmOptions('Are you sure?', "You will not be able to recover these contacts.", 'Delete');

                option.preConfirm = function() {
                    return vm.bulkDelete({ contacts: new Array(id.toString()) }).then(() => {
                        if (vm.$parent.$parent && vm.$parent.$parent.closeModal) {
                            vm.$parent.$parent.closeModal();
                        } else {
                            vm.$parent.closeModal();
                        }

                    });
                }

                Swal.fire(option);
            },

            addCard(action) {
                const vm = this;

                vm[action] = true;
            },

            viewProgressReport (progress) {
                const vm = this;

                vm.selectedRelation = progress.progress_tracking ? progress.progress_tracking : {};
                vm.progressReport   = true;
            },

            viewHabitReport (habit) {
                const vm = this;

                vm.selectedRelation = habit.habit_tracking ? habit.habit_tracking : {};
                vm.habitReport      = true;
            },

            toggleInfo (e, val) {
                const vm = this;
                let el   = e.currentTarget.closest('.profile_card');

                if (el.classList.contains('show')){
                    el.classList.remove('show');
                 } else {
                    el.classList.add('show');
                    vm.getActions(val);
                }
            },

            toggleAllCard () {
                const vm = this;
                let cardElements = document.querySelectorAll('.left_area .profile_card');

                vm.allOpen = !vm.allOpen;

                for (let i = 0; i < cardElements.length; i++) {
                    if (vm.allOpen) {
                        cardElements[i].classList.add('show');
                    } else {
                        cardElements[i].classList.remove('show');
                    }
                }
            },

            getRemainingDays (date) {
                let daysLeft = moment(moment(date))
                              .add( moment(moment().format("YYYY-MM-DD") )
                              .diff( moment(date), "years") + 1, "years" )
                              .diff( moment().format("YYYY-MM-DD"), "days" ) + ' Days';

                return daysLeft;
            },

            resetDetail () {
                const vm  = this;

                if (vm.selectedTags.length) {
                    vm.contactTags = [];
                    vm.contactTags = vm.filterTags(vm.selectedTags);
                }

                if (vm.statusesGroup && vm.statusesGroup.length == 0) {
                    vm.getStatuses();
                }

                if (vm.countries && vm.countries.length == 0) {
                    vm.getCountries();
                }

                if (vm.customFieldsAll && vm.customFieldsAll.length) {
                    vm.customFields = JSON.parse(JSON.stringify(vm.customFieldsAll));
                    vm.customFields = vm.customFields.reverse().slice(0, 5);
                }

                vm.resetTimezoneByCountry([]);
            },

            getTagBackground (type) {
                let color = '';

                if (type == 1) {
                    color = '#f2a31d'
                } else if (type == 2) {
                    color = '#2f7eed'
                } else if (type == 3) {
                    color = '#75D218'
                }

                return  color;
            },

            handleProfileSetting (view) {
                const vm = this;

                if (view === 'centre') {
                    vm.profileSetting.centre =  vm.centreView.map(({type}) => { return type ? type : null});
                } else if (view === 'right') {
                    vm.profileSetting.right  =  vm.rightView.map(({type}) => { return type ? type : null});
                }

                vm.updateContactProfileSetting(vm.profileSetting);
            },

            resetDetailView () {
                const vm = this;

                vm.profileSetting = {
                    user_id: vm.contactProfileSetting && vm.contactProfileSetting.user_id ? vm.contactProfileSetting.user_id : '',
                    left: vm.contactProfileSetting && vm.contactProfileSetting.left ? vm.contactProfileSetting.left  : null,
                    centre: vm.contactProfileSetting && vm.contactProfileSetting.centre ? vm.contactProfileSetting.centre  : null,
                    right: vm.contactProfileSetting && vm.contactProfileSetting.right ? vm.contactProfileSetting.right  : null,
                };

                let centre = [];
                let right  = [];

                if (vm.profileSetting.centre) {
                    vm.profileSetting.centre.forEach((centreType, i) => {
                        vm.centreView.find(({type}, index) => {
                            if (type && type === centreType) {
                                centre.push(vm.centreView[index]);
                            }
                        })
                    });
                }

                if (vm.profileSetting.right) {
                    vm.profileSetting.right.forEach((rightType, i) => {
                        vm.rightView.find(({type}, index) => {
                            if (type && rightType === type) {
                                right.push(vm.rightView[index]);
                            }
                        })
                    });
                }

                vm.centreView = centre.length ? centre : vm.centreView;
                vm.rightView  = right.length ? right : vm.rightView;
            },

            handleRemoveTag (tag, index) {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are You Sure?', "You want to remove tag for selected contact?")

                option.preConfirm = function() {

                    const form = {
                        tags_id: [tag.id],
                        contacts: [vm.contact.id],
                        action: '/contacts/remove-tag',
                        type: 'leads'
                    };

                    document.getElementById(`contact-profile-tag-${index}`).style.display = 'none';
                    return vm.removeTag(form)
                 };

                Swal.fire(option);
            },

            handleStopProgressTracking (id) {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are You Sure?', "You want to stop client tracking for selected contact?")

                option.preConfirm = function() {

                    const form = {
                        contacts: [vm.contact.id],
                        progress_tracking_ids: id,
                    };

                    return vm.stopProgress(form)
                 };

                Swal.fire(option);
            },

            handleStopHabitTracking (id) {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are You Sure?', "You want to stop habit tracking for selected contact?")

                option.preConfirm = function() {

                    const form = {
                        contacts: [vm.contact.id],
                        habit_tracking_ids: id,
                    };

                    return vm.stopHabit(form)
                 };

                Swal.fire(option);
            },

            handleRestrictForm (id) {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are You Sure?', "You want to restrict form for selected contact?")

                option.preConfirm = function() {

                    const form = {
                        contacts: [vm.contact.id],
                        form_id: id,
                        action: '/contacts/restrict-form',
                        sending_method: 1,
                    };

                    return vm.restrictForm(form);
                 };

                Swal.fire(option);
            },

                handleRestrictPlaybook (id) {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are You Sure?', "You want to restrict playbook for selected contact?")

                option.preConfirm = function() {

                    const form = {
                        contacts: [vm.contact.id],
                        playbook_id: id,
                        action: '/contacts/restrict-playbook',
                        sending_method: 1,
                    };

                    return vm.restrictPlaybook(form);
                 };

                Swal.fire(option);
            },

            handleRestrictPage (id) {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are You Sure?', "You want to restrict page for selected contact?")

                option.preConfirm = function() {

                    const form = {
                        contacts: [vm.contact.id],
                        page_id: id,
                        action: '/contacts/restrict-page',
                    };

                    return vm.restrictPage(form);
                 };

                Swal.fire(option);
            },

            handleTerminateJourney (id) {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are You Sure?', "You want to restrict journey for selected contact?")

                option.preConfirm = function() {

                    const form = {
                        contacts: [vm.contact.id],
                        journey_ids: id,
                        action: '/contacts/terminate-journey',
                        type: 'leads',
                    };

                    return vm.terminateJourney(form);
                 };

                Swal.fire(option);
            },

            handleStopSequence (id) {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are You Sure?', "You want to stop sequence for selected contact?")

                option.preConfirm = function() {

                    const form = {
                        contacts: [vm.contact.id],
                        sequence_id: id,
                        action: '/contacts/stop-sequence',
                        type: 'leads',
                    };

                    return vm.stopSequence(form);
                 };

                Swal.fire(option);
            },

            viewFormDetail (form) {
                const vm = this;

                const formData = {
                    contact_id: form.contact_id,
                    survey_id: form.survey_id,
                    respondent_id: form.respondent_id,
                }

                vm.viewFormResponse(formData).then((result) => {
                    if (result) {
                        vm.formDetails = {
                            respondent_info: {
                                name: result.respondent_info && result.respondent_info.name ? result.respondent_info.name : '',
                                email: result.respondent_info && result.respondent_info.email ? result.respondent_info.email : '',
                                number: result.respondent_info && result.respondent_info.number ? result.respondent_info.number : '',
                                full_address: result.respondent_info && result.respondent_info.full_address ? result.respondent_info.full_address : '',
                                birthdate: result.respondent_info && result.respondent_info.birthdate ? result.respondent_info.birthdate : '',
                            },
                            survey_info: result.survey_info,
                            survey_questions: result.survey_questions,
                            signatures: result.signatures,
                            custom_fields: result.custom_fields,
                            custom_fields_answers: result.custom_fields_answers,
                        };

                        vm.showFormDetail = true;
                    }
                });
            },

            getActions (val, force = false) {
                const vm = this;

                if (!force && vm.opendTab.includes(val)) {
                    return;
                }

                vm.selectedTab = val;
                vm.opendTab.push(val);

                const id = vm.contact.id ? vm.contact.id : '';

                let params = {
                    page: 1,
                    per_page: 5,
                    id,
                }

                let params2 = {
                    page: 1,
                    per_page: 5,
                    contact_id: id,
                }

                if (val == 'Progress Scenarios') {
                    vm.getContactProgressTracking(params2);
                } else if (val == 'Habit Scenarios') {
                    vm.getContactHabitTracking(params2);
                } else if (val == 'Pages') {
                    vm.getContactPages(params);
                } else if (val == 'Journeys') {
                    vm.getContactJourneys(params);
                } else if (val == 'Playbooks') {
                    vm.getContactPlaybooks(params);
                } else if (val == 'Forms') {
                    vm.getContactForms(params2);
                } else if (val == 'Tags') {
                    vm.getContactTags(id);
                } else if (val == 'Rewards') {
                    vm.getContactRewardPoints(id);
                } else if (val == 'Sequences') {
                    vm.getContactSequences(params);
                } else if (val == 'Broadcasts') {
                    vm.getContactBroadcastLogs(params);
                }
            },

            handleEnableDisableForm (form, ev) {
                const vm = this;

                const formData = {
                    client_portal: ev.target.checked ? 1 : 0,
                    employer_id: form.employer_id,
                    survey_id: form.survey_id,
                    survey_name: form.survey_name,
                    user_id: form.contact_id,
                }

                vm.enableDisableForm(formData).then((result) => {
                    if (!result) {
                        setTimeout(function () {
                            ev.target.checked = false;
                        }, 500);
                    }
                });
            },

            handleEnableDisablePage (page, ev) {
                const vm = this;

                const params = {
                    client_portal: ev.target.checked ? 1 : 0,
                    asset_id: page.asset_id,
                    user_id: vm.contact.id,
                }

                vm.enableDisablePage(params).then((result) => {
                    if (!result) {
                        ev.target.checked = false;
                    }
                });
            },

            hasActiveContent(el){
                const vm = this;

                console.log(vm.broadcasts.data);

                if(el.type == 'broadcasts'){
                    return 'Active';
                }else{
                    return '';
                }
            }
        }
    }
</script>

<style>
    .details_section{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        height: calc(100vh - 130px);
        overflow-y: overlay;
        padding-bottom: 30px;
        gap: 30px;
    }
    .details_section::-webkit-scrollbar {
      display: none;
    }
    .left_area{
        /* width: calc(100% - 365px); */
        max-width: 1170px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .profile_card{
        background: #fff;
        border-radius: 8px;
        overflow: hidden;
    }
    .profile_card .card_hdr{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 15px 15px 20px;
        cursor: move;
        position: relative;
    }
    .profile_card .card_hdr.band:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 4px;
        background: rgb(0, 170, 20, 0.3);
    }
    .profile_card .card_hdr .toggle_btn{
        width: 20px;
        height: 20px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        /* background: #eee; */
        font-size: 10px;
        color: #999;
        transition: all 0.5s ease-in-out;
    }
    .profile_card .card_hdr h3{
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
    }
    .profile_card .card_body{
        padding: 20px;
        border-top: 1px solid #eee;
        display: flex;
        flex-direction: column;
        gap: 30px;
        max-height: 420px;
        overflow-y: overlay;
        display: none;
    }
    .profile_card.show{
        flex-grow: 1;
        height: 100%;
    }
    .profile_card.show .card_body{
        display: flex;
    }
    .profile_card.show .card_hdr .toggle_btn{
        transform: rotate(-180deg);
    }
    /* .profile_card .card_body::-webkit-scrollbar {
      display: none;
    } */
    .profile_card .card_body::-webkit-scrollbar {
      width: 4px;
    }
    .profile_card .card_body:hover::-webkit-scrollbar-thumb {
      background-color: #dbdbdb;
      border-radius: 2px;
    }
    .profile_card .card_body h4{
        font-size: 14px;
        line-height: 20px;
        color: #121525;
        display: flex;
        align-items: center;
        font-weight: 500;
        margin: 0 0 12px 0;
        /* overflow: hidden; */
        display: flex;
        position: relative;
    }
    .profile_card .card_body h4 ul{
        display: flex;
        align-items: center;
        margin-left: 20px;
        gap: 20px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    .profile_card .card_body h4 ul li{
        display: flex;
        align-items: center;
    }
    .profile_card .card_body h4 .del_btn{
        font-size: 12px;
        color: #ff0000;
        position: relative;
        margin: 0 -30px 0 auto;
        padding-left: 15px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }
    .profile_card .card_body .status_list, .profile_card .card_body .playbook_status{
        flex: 0 0 auto;
        overflow: hidden;
    }
    .profile_card .card_body .status_list.negetive_margin{
        margin: 0 -30px;
    }
    .profile_card .card_body .status_list.negetive_margin h4{
        padding: 0 30px;
    }
    .profile_card .card_body .status_list.negetive_margin h4 .del_btn{
        margin-right: -45px;
    }
    .profile_card .card_body .status_list:hover h4 .del_btn, .profile_card .card_body .playbook_status:hover h4 .del_btn{
        margin-right: 5px;
        padding-left: 0;
    }
    .profile_card .card_body .status_list:hover h4 ul{
        opacity: 1;
    }
    .profile_card .card_body .status_list_item{
        margin-bottom: 25px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .profile_card .card_body .status_list_item h4{
        margin: 0 20px 10px 0;
        max-width: 400px;
    }
    .profile_card .card_body h6{
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 500;
    }
    .profile_card .card_body h6.delivered{
        color: #00aa14;
        background: rgba(0, 170, 20, 0.15);
        padding: 3px 12px;
        display: inline-block;
        border-radius: 10px;
    }
    .profile_card .card_body h6.not_delivered{
        color: #F2A31D;
        background: rgba(242, 163, 29, 0.15);
        padding: 3px 12px;
        display: inline-block;
        border-radius: 10px;
    }
    .profile_card .card_body h6.opened{
        color: #2f7eed;
        background: rgba(47, 126, 237, 0.15);
        padding: 3px 12px;
        display: inline-block;
        border-radius: 10px;
    }
    .profile_card .card_body h5{
        font-size: 13px;
        line-height: 17px;
        font-weight: 500;
        color: #121525;
    }
    .profile_card .card_body h5 span{
        font-size: 10px;
        line-height: 12px;
        color: #5a5a5a;
        display: block;
        margin-top: 4px;
    }
    .profile_card .card_body .action_status{
        border: 1px solid #eee;
        border-radius: 5px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 5px;
        padding: 0;
        align-items: stretch;
    }
    .profile_card .card_body .action_status li{
        flex: 1 0 20%;
        padding: 15px;
        border-right: 1px solid #eee;
        flex-direction: column;
        align-items: flex-start;
    }
    .profile_card .card_body .action_status.broadcast{
        border: 0;
        overflow: hidden;
        margin-bottom: 0;
    }

    .profile_card .card_body .action_status.broadcast li{
        background: #f6f6f6;
        padding: 7px 15px;
        border-radius: 15px;
        border: 0;
        flex: 0 0 auto;
        margin: 0 10px 5px 0;
    }
    .profile_card .card_body .action_status.broadcast li .broadcast_step{
        display: flex;
        align-items: center;
    }
    .profile_card .card_body .action_status.broadcast li .broadcast_step h3{
        font-size: 13px;
        line-height: 18px;
        font-weight: 300;
        padding-right: 8px;
    }
    .profile_card .card_body .action_status.broadcast li .broadcast_step p{
        font-size: 13px;
        line-height: 18px;
        margin-top: 0
    }

    /* .profile_card .card_body .action_status.broadcast li{
        border: 0;
        padding: 0;
        flex: 0 0 25%;
        position: relative;
        line-height: 0;
    }
    .profile_card .card_body .action_status.broadcast li .broadcast_step{
        position: relative;
        padding: 16px 0 16px 20px;
        background: #eee;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 5px;
    }
    .profile_card .card_body .action_status.broadcast li .broadcast_step .curve_space{
        border-left: 30px solid #dfdfdf;
        border-right: 0 solid transparent;
        border-top: 35px solid transparent;
        border-bottom: 35px solid transparent;
        position: absolute;
        left: 100%;
        z-index: 1;
        top: 2px;
    }
    .profile_card .card_body .action_status.broadcast li .broadcast_step.check{
        background: #dfdfdf;
    }
    .profile_card .card_body .action_status.broadcast li:first-child .broadcast_step{
        align-items: flex-start;
    }
    .profile_card .card_body .action_status.broadcast li:not(:first-child) .broadcast_step{
        padding: 16px 5px 16px 40px;
    }
    .profile_card .card_body .action_status.broadcast li:not(:last-child):after{
        position: absolute;
        content: '';
        top: 0;
        right: -30px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        height: 100%;
        width: 35px;
        background-image: url("data:image/svg+xml,%3Csvg width='30' height='70' viewBox='0 0 30 70' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 0L30 35L4 70H0L26 35L0 0H4Z' fill='white'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    } */
    .profile_card .card_body .action_status li:last-child{
        border: 0;
    }
    .profile_card .card_body .action_status li h3{
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
    }
    .profile_card .card_body .action_status li p{
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 500;
        margin-top: 7px;
    }
    .profile_card .card_body .playbook_status{
        border: 1px solid #eee;
        border-radius: 5px;
        padding: 15px;
    }
    .setting_form{
        display: flex;
        flex-direction: column;
        height: calc(100vh - 111px);
    }

    :deep(.global_setting .tabs_content .setting_form > .action_wpr) {
        position: sticky;
        left: -45px;
        right: -45px;
        bottom: 0;
        margin: auto -45px 0 -45px;
        border-top: 1px solid #e9e9e9;
    }

    .details_section .swiper_section{
        position: relative;
        margin: 0;
        border-radius: 5px;
    }
    .seq_listing{
        padding: 0 30px;
    }
    .seq_listing:before{
        position: absolute;
        content: '';
        width: 30px;
        left: 0;
        top: 0;
        bottom: 0;
        background: #fff;
        z-index: 2;
    }
    .seq_listing:after{
        position: absolute;
        content: '';
        width: 30px;
        right: 0;
        top: 0;
        bottom: 0;
        background: #fff;
        z-index: 2;
    }
    .seq_listing::-webkit-scrollbar {
      display: none;
    }
    .seq_listing .swiper-slide {
        width: 210px;
    }
    .seq_listing .swiper-slide:not(:last-child):after{
        position: absolute;
        content: '';
        top: 0;
        right: -30px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        height: 100%;
        width: 35px;
        background-image: url("data:image/svg+xml,%3Csvg width='30' height='70' viewBox='0 0 30 70' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 0L30 35L4 70H0L26 35L0 0H4Z' fill='white'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    .seq_listing .swiper-button-next, .seq_listing .swiper-button-prev{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        top: 66%;
    }
    .seq_listing .swiper-button-next{
        right: 7px;
    }
    .seq_listing .swiper-button-prev{
        left: 7px;
    }
    .seq_listing .swiper-button-next.swiper-button-disabled, .seq_listing .swiper-button-prev.swiper-button-disabled{
        opacity: 0;
    }
    .seq_listing .swiper-button-next:after, .seq_listing .swiper-button-prev:after{
        font-size: 10px;
        color: #999;
        font-weight: 600;
    }
    .profile_card .seq_wpr{
        padding: 16px 0 16px 15px;
        background: #eee;
        position: relative;
        border-radius: 5px;
        display: flex;
        gap: 15px;
        align-items: flex-start;
    }
    .profile_card .seq_wpr label{
        font-size: 14px;
        line-height: 18px;
        color: #5a5a5a;
        position: relative;
    }
    .profile_card .seq_listing .swiper-slide:not(:first-child) .seq_wpr{
        padding: 16px 5px 16px 45px;
    }
    .profile_card .seq_wpr .curve_space{
        border-left: 30px solid #eee;
        border-right: 0 solid transparent;
        border-top: 35px solid transparent;
        border-bottom: 35px solid transparent;
        position: absolute;
        left: 100%;
        z-index: 1;
        top: 2px;
    }
    .profile_card .seq_wpr.schedule{
        opacity: 0.5;
    }
    .profile_card .seq_wpr .seq_method{
        display: flex;
        align-items: center;
        gap: 10px;
        position: absolute;
        right: 10px;
        top: 28px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    .profile_card .seq_wpr:hover .seq_method{
        opacity: 1;
    }
    .profile_card .seq_wpr .seq_method li img{
        max-height: 14px;
        width: auto;
        filter: grayscale(1);
    }
    .profile_card .card_body .seq_wpr .action_info .action{
        font-size: 14px;
        line-height: 17px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 10px;
        position: relative;
    }
    .profile_card .card_body .seq_wpr .action_info p{
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 500;
    }



    .profile_card .card_body p{
        font-size: 14px;
        line-height: 20px;
    }
    .profile_card .card_body .date{
        margin-top: 30px;
        display: flex;
        font-size: 11px;
        line-height: 14px;
        justify-content: flex-end;
    }
    .tracking_list li h4 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #32373b;
    }
    .report_list{
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        /* margin: 0 -15px; */
    }
    .report_list > li{
        /* padding: 15px; */
        flex: 1 0 330px;
        /* width: 50%; */
        display: flex;
        flex-direction: column;
    }
    .profle_details > li{
        flex-direction: column;
    }
    .report_list > li .add_btn, .profle_details > li .add_btn{
        margin: 0 5px 0 auto;
        position: relative;
    }
    .quick_info{
        border-radius: 5px;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 15px rgb(0 0 0 / 10%);
        background: #fff;
        max-width: 160px;
        width: max-content;
        text-align: left;
        position: absolute;
        right: -5px;
        top: 100%;
        display: none;
        margin-top: 5px;
        padding: 7px 10px;
        z-index: 2;
        flex-shrink: 1;
    }
    .quick_info p{
        font-size: 9px !important;
        line-height: 12px !important;
        color: #5a5a5a;
        font-weight: 500;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        /* height: 24px; */
    }
    .quick_info:after{
        content: "";
        position: absolute;
        top: -5px;
        right: 8px;
        width: 8px;
        height: 8px;
        border-top: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        background: #fff;
        transform: rotate(-45deg);
        z-index: 2;
    }
    .profile_card .card_body .seq_wpr label .quick_info, .profile_card .card_body .seq_wpr .action .quick_info{
        right: auto;
        left: -15px;
        margin: 0;
    }
    .profile_card .card_body .seq_wpr label .quick_info:after, .profile_card .card_body .seq_wpr .action .quick_info:after{
        right: auto;
        left: 15px;
    }
    .profile_card .card_body .seq_wpr label .quick_info p, .profile_card .card_body .seq_wpr .action .quick_info p{
        height: 24px;
    }
    .profile_card .card_body .seq_wpr label:hover .quick_info, .profile_card .card_body .seq_wpr .action:hover .quick_info, .report_list > li .add_btn:hover .quick_info, .report_list > li .del_btn:hover .quick_info{
        display: flex;
        animation: slide_to_top 0.5s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    @keyframes slide_to_top {
        0% {top: 30%; opacity: 0;}
        10% {top: 30%; opacity: 0.5;}
        100% {top: 100%; opacity: 1;}
    }

    :deep(.profileTab_content > form > .action_wpr) {
        background: #fff !important;
    }

    .btn_confirm {
        display: flex;
        justify-content: flex-start;
    }
    .action_btn{
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 10px;
    }
    .action_btn .btn{
        padding: 8px 15px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #121525;
        border-radius: 3px;
        border: 1px solid #dbdde1;
        text-transform: uppercase;
        cursor: pointer;
    }
    .action_btn .btn.save_btn{
        background: #999;
        color: #fff;
        border: 0;
    }

    .info_card .btn_confirm button.unconfirm {
        border: 1px solid #9FB1CF;
        background: #fff;
        color: rgba(104, 129, 166, 0.9);
        font-weight: 400;
    }

    .info_card .btn_confirm button {
        height: 20px;
        white-space: nowrap;
        padding: 0 10px;
        font-size: 10.5px;
        line-height: 13px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        border-radius: 10px;
        margin: 2px 4px 0 0;
    }

    .edit_details{
        width: calc(100% - 320px);
        height: 100vh;
        background: #f5f5f5;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 5;
        animation: smoothMove 0.8s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    @keyframes smoothMove {
      from {right: -100%;}
      to {right: 0;}
    }
    .edit_details .header{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 40px 30px 20px 30px;
    }

    .edit_details .header h1{
        font-size: 27px;
        line-height: 33px;
        font-weight: 500;
    }
    .edit_details .header .close_btn{
        width: 20px;
        height: 20px;
        position: absolute;
        top: 20px;
        left: auto;
        right: 30px;
        cursor: pointer;
    }
    .edit_wpr{
        padding: 0 30px 10px 30px;
        height: calc(100vh - 174px);
        overflow-y: scroll;
    }
    .edit_wpr::-webkit-scrollbar {
      display: none;
    }
    .edit_list{
        display: flex;
        flex-wrap: wrap;
        padding: 15px 0;
        background: #fff;
        border-radius: 8px;
    }
    .edit_list li{
        width: 33.333%;
        padding: 20px 30px;
        /* border-right: 1px solid #f5f5f5;
        border-bottom: 1px solid #f5f5f5; */
        transition: all 0.3s ease-in-out;
    }
    .edit_list li:hover{
        box-shadow: 0 1px 30px rgba(0,0,0,0.05);
    }
    .edit_list li .edit_card h4{
        font-size: 13px;
        line-height: 17px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 5px;
        text-align: left;
    }
    .edit_list li .edit_card .field_wpr{
        border: 0;
        border-bottom: 1px solid #e9e9e9;
        border-radius: 0;
    }
    .edit_list li .edit_card .field_wpr input{
        height: 40px;
        padding: 0;
        font-size: 13px;
    }
    .edit_list li .edit_card .vti__dropdown{
        padding: 5px 7px;
    }
    .edit_list li .edit_card .multiselect{
        min-height: 40px;
        border: 0;
        background: transparent;
    }
    .edit_list li .edit_card .multiselect-single-label{
        font-size: 12px;
        line-height: 15px;
        padding-left: 0;
    }
    .edit_list li .edit_card .multiselect-option.is-selected{
        background: #8f8f8f;
    }
    .edit_list li .edit_card .multiselect .multiselect-options li{
        font-size: 12px;
        padding: 5px;
    }
    .edit_list li .edit_card .multiselect.is-active{
        box-shadow: none ;
    }
    .edit_list li .edit_card .status_bar{
        margin: 8px 0 0;
    }

    :deep(.form_content .action_wpr) {
        position: sticky;
        bottom: 0;
        left: 0;
        background: #fff;
        border-top: 1px solid #e9e9e9;
        padding: 20px 30px;
        margin: auto -30px 0 -30px;
    }


    .info_wpr{
        display: flex;
        flex-wrap: wrap;
        /* margin: 0 0 45px ; */
        /* padding: 25px 23px 35px 23px;
        border: 1px solid #e9e9e9; */
        border-radius: 6px;
        /* box-shadow: 0 0 20px rgba(0,0,0,0.08); */
        position: relative;
    }
    /* .info_wpr:before{
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        height: 4px;
        background: #f2a31d;
        border-radius: 6px 6px 0 0;
    } */
    .info_wpr .border_item{
        flex: 1 0 50%;
        padding: 15px 7px;
    }
    .info_wpr .border_item h5{
        font-size: 13px;
        line-height: 20px;
        color: #121525;
        font-weight: 400;
        margin-bottom: 6px;
    }
    .info_wpr .border_item p{
        /* border: 1px solid #d9d9d9; */
        padding: 0;
        font-size: 16px;
        line-height: 32px;
        color: #5a5a5a;
    }
    .answer_item{
        padding: 5px;
        font-size: 10px;
        line-height: 12px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        margin: 7px 0 15px;
        min-height: 30px;
    }
    .form_details{
        padding: 30px;
        border: 1px solid #e9e9e9;
        border-radius: 6px;
        position: relative;
        margin-bottom: 45px;
    }
    /* .form_details:before{
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        height: 4px;
        background: #f2a31d;
        border-radius: 6px 6px 0 0;
    } */
    .form_details .form_wrapper{
        display: flex;
        flex-direction: column;
        padding: 0 7px;
        border: 1px solid #edecec;
        padding: 20px 20px 10px 20px;
        border-radius: 6px;
        margin-bottom: 25px;
    }
    .form_details label{
        font-size: 15px;
        line-height: 22px;
        color: #121525 !important;
        font-weight: 500;
        margin-bottom: 15px;
    }
    .form_details p{
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: #5a5a5a !important;
        margin: 0 !important;
    }

    .question_option{
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 500;
        margin-bottom: 14px;
    }
    .question_option i{
        margin-right: 5px;
    }

    .form_view{
        width: calc(100% - 320px);
        height: calc(100vh);
        background: #f5f5f5;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 4;
        animation: smoothScale 0.6s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    @keyframes smoothScale {
        from {transform: scale(0);}
        to {transform: scale(1);}
    }
    .form_view .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px;
        border-bottom: 1px solid #eee;
    }
    .form_view .widgets_wpr .header{
        padding: 0;
        border: 0;
    }

    .form_view .header h1{
        font-size: 27px;
        line-height: 33px;
        font-weight: 500;
    }
    .form_view .header .close_btn{
        top: 20px;
        left: auto;
        right: 30px;
    }
    .form_view .edit_wpr {
        padding: 30px;
        height: 100vh;
        overflow-y: scroll;
    }
    .doc_container{
        border-radius: 8px;
        max-width: 800px;
        margin: 70px auto 30px auto;
        background: #fff;
    }
    .doc_container .content_body{
        padding: 40px 30px;
    }
    .doc_container .content_body h1{
        font-size: 22px;
        line-height: 30px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 20px;
    }
    .form_view .close_btn{
        left: auto;
        right: 20px;
    }
    .form_view .report_section{
        max-height: 100vh !important;
    }
    .form_view .report_section .report_card{
        background: #fff;
    }

    .profile_card .card_body h2 {
        font-size: 14px;
        line-height: 18px;
        color: #121525;
        display: flex;
        align-items: center;
        font-weight: 500;
        margin: 0 0 12px 0;
        display: flex;
        position: relative;
        justify-content: center;
    }

    .toggle_card{
        font-size: 11px;
        line-height: 13px;
        color: #121525;
        font-weight: 500;
        position: absolute;
        right: 3px;
        top: 5px;
        cursor: pointer;
    }
    .contact_profile .add_btn{
        font-size: 11px;
        line-height: 14px;
    }
    .contact_profile .title .add_btn{
        font-size: 13px;
        line-height: 16px;
        margin: 0 0 0 15px;
    }
    .contact_profile .add_btn i{
        font-size: 9px !important;
    }
    .contact_profile .search_panel .search_btn{
        height: 27px;
        font-size: 11px;
    }
    .profile_stat .score_circle .inner_circle{
        background: #fff;
    }
    .profile_stat .score_circle .inner_circle .score {
        font-size: 12px;
        line-height: 16px;
    }
    .contact_profile .tab_slider .swiper-button-next{
        right: 0;
        bottom: 12px;
    }
    .contact_profile .tab_slider .swiper-button-prev {
        left: 0;
        bottom: 12px;
    }
    .profileTab_content .empty_list {
        flex: 1 0 400px;
        max-height: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-radius: 8px;
        padding: 20px;
        position: sticky;
        top: 0;
    }
    .profileTab_content .empty_list img {
        max-height: 90px;
        width: auto;
        max-width: 100%;
        margin-bottom: 30px;
    }
    .profileTab_content .empty_list h4{
        font-size: 16px;
        line-height: 23px;
        font-weight: 400;
        color: #5a5a5a;
        text-align: center;
    }
    .profileTab_content .pagination{
        max-width: none;
        margin-top: 27px;
    }
    .no_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .no_content img {
        max-height: 60px;
        width: auto;
        max-width: 100%;
        margin-bottom: 15px;
    }
    .no_content h3{
        font-size: 15px;
        line-height: 22px;
        font-weight: 400;
        color: #5a5a5a;
        text-align: center;
    }
    .page_item .form_grp .multiselect .multiselect-dropdown{
        min-width: auto;
    }
    @media(max-width: 1399px){
        .form_view{
            width: calc(100% - 100px);
        }
    }
    @media(max-width: 1199px){
        .details_section .action_status{
            padding: 12px 20px;
        }
        .details_section .action_status li{
            padding-right: 15px;
        }
    }
    @media(max-width: 991px){
        .edit_details, .form_view{
            width: 100%;
        }
        .profle_details{
            flex: 0 1 295px;
        }
        /* .left_area {
            width: calc(100% - 325px);
        } */
        .details_section .action_status {
            padding: 10px;
        }
        .details_section .action_status li{
            padding-right: 12px;
        }
        .details_section .profile_stat .action_status{
            padding: 12px 20px;
        }
        .edit_list li{
            padding: 20px;
        }
    }

    @media(max-width: 768px){
        .left_area{
            width: 100%;
        }
        .profle_details{
            flex: 1 1  auto;
            flex-direction: row;
            flex-wrap: wrap;
        }
        .profle_details > li{
            flex: 1 0 50%;
            min-width: 235px;
        }
        .profle_details li .info_card{
            height: 100%;
        }
        .profle_details li .info_card h2{
            font-size: 18px;
            line-height: 25px;
        }
        .doc_container .content_body{
            padding: 35px;
        }
        .doc_container .info_wpr .border_item p{
            height: 40px;
            font-size: 13px;
            line-height: 38px;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .doc_container .info_wpr .border_item h5{
            font-size: 14px ;
        }
        .doc_container .content_body h1{
            font-size: 20px;
            line-height: 25px;
        }
        .details_section .profile_stat .action_status{
            flex: 1 1 calc(100% - 30px);
        }
        .edit_list li{
            width: 50%;
        }
    }
    @media(max-width: 499px){
        .doc_container .content_body, .doc_container .letter_head{
            padding: 20px;
        }
        .doc_container .letter_head .comp_logo{
            height: 30px;
        }
        .doc_container .letter_head .comp_name h3{
            font-size: 16px;
        }
        .doc_container .letter_head .comp_name address{
            font-size: 11px;
        }
        .doc_container .info_wpr{
            flex-direction: column;
        }
        .doc_container .info_wpr{
            margin-bottom: 35px;
        }
        .profile_stat .score_circle{
            transform: scale(1);
        }
        .edit_wpr{
            padding: 0 20px 10px 20px;
        }
        .edit_list li{
            width: 100%;
        }
    }
    @media(max-width: 450px){
        .details_section .action_status{
            flex: 0 0 100%;
            padding: 10px 20px;
        }
        .details_section .action_status:not(:last-child){
            border-right: 0;
            border-bottom: 1px solid #eee;
        }
        .profile_notes .title h3{
            font-size: 17px;
            line-height: 22px;
        }
    }

    .profileTab_content .action_wpr {
        padding: 10px 40px;
    }

    .custom-field-ans {
        font-size: 13px;
        color: #5a5a5a;
        font-weight: 400;
    }
</style>
